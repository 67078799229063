

import {
  IonItem,
  IonLabel,
  IonBadge,
  IonInput,
  IonButton,
  toastController,
  IonCol, IonGrid, IonRow,
  alertController  
} from "@ionic/vue";

import {  ref, reactive, computed, watch } from "vue";
import BaseLayout from "@/components/BaseLayout.vue";

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getAuth } from "firebase/auth";


interface CarValues {
  "Etiqueta Pequena": number | undefined;
  "Etiqueta Grande": number | undefined;
  "Suporte": number | undefined;
  "Crachá": number | undefined;
  "Imagem": number | undefined;
}

export default {
    name: "Car",
    components: {
        IonItem,
        IonLabel,
        IonBadge,
        IonInput,
        IonButton,
        IonCol, IonGrid, IonRow,
        BaseLayout,
    },

    setup() {

        const router = useRouter();
        const store = useStore();
        const isSaved = ref(false);
        const selectedItem = ref("");
        const discount= ref(0.00);
        const total = ref(0.00);
        const totalBruto = ref(0.00);
        const business = ref('/' + store.getters.getBusiness + '/qrgenerator')

        

        const car = reactive({
            "Etiqueta Pequena" : 0,
            "Etiqueta Grande" : 0,
            "Suporte" : 0,
            "Crachá" : 0,
            "Imagem" : 0,
        })

        async function openToast(message: string) {
            const toast = await toastController
                .create({
                message: message,
                duration: 4000
                })
            return toast.present();
        }



        setInterval(()=>{
            if (! getAuth().currentUser)
                router.push("/signin");
        }, 2000);


        function addCar(item: string) {
            if (item in car) {
                car[item as keyof CarValues] +=  1
            }
        }

        function clearCar() {
            let k: keyof CarValues
            for (k in car) {
                car[k] = 0;
            }
            openToast("Carrinho zerado")
        }

        function subCar(item: string) {
            console.log(`Deletado ${item}`)
            if (car [item as keyof CarValues] > 0)
                car[item as keyof CarValues] -= 1;
        }

        function checkout() {
            console.log('Checkout rumo ' + '/' + store.getters.getBusiness + '/label');
            business.value = '/' + store.getters.getBusiness + '/qrgenerator';
            router.push('/' + store.getters.getBusiness + '/label')
        }

        const canSave = computed(() => {
            let k: keyof CarValues;
            for (k in car) {
                if (car[k] !== 0 ) return true
            }
            return false
        })

        function calcTotal() {
            const appInfo = store.getters.getAppInfo;
            //TODO: trazer os valores individuas do banco de dados
            let k: keyof CarValues;
            let value = 0;
            for (k in car) {
                switch (k) {
                    case 'Etiqueta Grande' :
                        value += car[k] * appInfo.prices[k];
                        break 
                    case 'Etiqueta Pequena' :
                        value += car[k] * appInfo.prices[k];
                        break 
                    case 'Suporte' :
                        value += car[k] * appInfo.prices[k];
                        break 
                    case 'Crachá' :
                        value += car[k] * appInfo.prices[k];
                        break
                    case 'Imagem' :
                        value += car[k] * appInfo.prices[k];
                }
            }
            return value;
        }

        watch(car, () => {
            total.value = calcTotal();
            if (total.value > discount.value)
                total.value = total.value - discount.value;
            totalBruto.value = calcTotal();
        })

        watch(discount, () => {
            console.log("Tentando aplicar desconto")
            if (calcTotal() > discount.value) {
                console.log("Aplicando desconto")
                total.value = calcTotal() -  discount.value;
            }
        })

        async function saveCar() {
            const label = store.getters.getLabel;
            let k: keyof CarValues
            label['car'] = {};
            for (k in car) {
                label.car[k] = car[k];
            }
            label.car['discount'] = discount.value;
            label.car['total'] = total.value;

            const alert = await alertController.create({
                header: 'Atenção',
                subHeader: 'Salvar Carrinho',
                message: 'Confirma salvar o carrinho com a compra?',
                buttons: [
                    {
                        text: 'Salvar',
                        role: 'save',
                        cssClass: 'secondary',
                    }, 
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                    }
                ],
            })
            await alert.present();
            const { role } = await alert.onDidDismiss();
            console.log(role);
            if ( role == 'save') {
                store.commit('setLabel', label);
                await store.commit('saveOnFirebase');
                openToast("Dados salvos!")
                isSaved.value=true;
                router.push('/' + store.getters.getBusiness + '/label');
            }
        }

        return {
            saveCar,
            subCar,
            clearCar,
            addCar,
            selectedItem,
            car,
            canSave,
            isSaved,
            checkout,
            discount,
            total,
            totalBruto,
            business
        }

    }
}
